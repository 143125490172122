import Popup from '@/components/popup.vue';
import { ITable } from '@/components/table';
import Table from '@/components/table/table.vue';
import store from '@/store';
import { IStateEvent } from '@/store/modules/events';
import { IEventsTranslations } from '@/translations';
import sort from '@/utils/sort';
import { Component, Vue } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { Action, Getter, Mutation } from 'vuex-class';

import { eventActionPopup, table } from '.';

@Component({
  name: 'Events',
  components: { Table, Popup }
})
class Events extends Vue {
  @Action('fetchEvents') public fetch!: () => void;

  @Mutation('selectEvent') public selectEvent!: (event: IStateEvent) => void;

  @Getter('getEvents') public events!: IStateEvent[];

  @Getter('getEventsTranslations') public translations!: IEventsTranslations;
  public table = {} as ITable<IStateEvent>;
  public eventActionPopup = eventActionPopup;
  public created() {

    this.fetch();

    this.table = table(this.translations);
    this.table.onSelectedItem = this.onEventSelected;

    store.subscribe((mutation: MutationPayload) => {

      if (mutation.type === 'setLanguage') {

        this.table = { ...this.table, ...table(this.translations) };

      }

      if (mutation.type !== 'selectEvent') {

        const key = Object.keys(this.table.order) as Array<Extract<IStateEvent, string>>;
        const field = key[0];
        const order = this.table.order[key[0]];

        if (mutation.type !== 'setEventsLoading') {
          this.table = {
            ...this.table,
            data: sort(field, order, this.events)
          };
        }

        if (mutation.type === 'newEvent') {
          this.triggerPopup({
            message: this.translations.created_success,
            type: 'success'
          });
        }
      }
    });

  }
  public onEventSelected(event: IStateEvent) {
    this.selectEvent(event);
  }
  public triggerPopup({ message, type }: { message: string, type: string }) {
    this.eventActionPopup = {
      ...this.eventActionPopup,
      isActive: true,
      message,
      type,
    };
  }
}

export default Events;
